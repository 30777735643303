import { useState } from 'react';

import { VerticalTimelineElement } from 'react-vertical-timeline-component';
import { ArrowDownCircleIcon, ArrowUpCircleIcon, WrenchScrewdriverIcon } from '@heroicons/react/24/solid';

import Divider from './Divider';

import hsLogo from "../assets/hs_logo.jpg";
import codazenLogo from "../assets/codazen_logo.jpg";
import gsLogo from "../assets/green_street_logo.jpg";
import jlmLogo from "../assets/jlm_logo.jpg";
import babylistLogo from "../assets/babylist_logo.jpg";

type ExperienceJobCardProps = {
  dark: boolean;
  date: string;
  companyName: string;
  isAccent: boolean;
  jobTitle: string;
  jobLocation: string;
  responsibilities: string[];
  accomplishments: string[];
}

function ExperienceJobCard({ dark, date, companyName, isAccent, jobTitle, jobLocation, responsibilities, accomplishments }: ExperienceJobCardProps) {
  const [open, setOpen] = useState(false);

  const accentContentStyle = {
    borderRadius: "0.75rem",
    backgroundImage: dark ? "linear-gradient(to right bottom, #eab308 50%, #f59e0b)" : "linear-gradient(to right bottom, #3b82f6 50%, #06b6d4)",
    boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
    padding: 0
  }
  const defaultContentStyle = {
    borderRadius: "0.75rem",
    backgroundImage: dark ? "linear-gradient(to right bottom, #64748b 50%, #94a3b8)" : "linear-gradient(to right bottom, #e2e8f0 50%, #cbd5e1)",
    boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
    padding: 0
  }
  const accentArrowStyle = {
    borderRight: `7px solid ${dark ? "#eab308" : "#3b82f6"}`,
  }
  const defaultArrowStyle = {
    borderRight: `7px solid ${dark ? "rgb(107 114 128)" : "rgb(226 232 240)"}`,
  }
  const iconStyle = {
    boxShadow: `0 0 0 4px ${dark ? "rgb(107 114 128)" : "rgb(226 232 240)"}`,
    background: "white",
    overflow: "hidden"
  }
  const defaultTextStyle = `${isAccent ? "text-white dark:text-black" : "text-black dark:text-white"}`;
  const chevronIconStyle = `size-8 mx-auto mt-2 ${defaultTextStyle} animate-bounce`;
  const defaultIconStyle = `size-8 mx-auto mt-2 ${defaultTextStyle}`

  const CompanyLogo = ({ companyName }: { companyName: string }) => {
    let src = ""
    switch(companyName) {
      case "Handshake":
        src = hsLogo;
        break;
      case "Codazen":
        src = codazenLogo;
        break;
      case "Green Street":
        src = gsLogo;
        break;
      case "JLM":
        src = jlmLogo;
        break;
      case "Babylist":
        src = babylistLogo;
        break;
    }

    return (
      <img src={src} alt={`${companyName} logo`} />
    )
  }

  const ExperienceHeader = ({ title }: { title: string }) => {
    return (
      <h1 className={`vertical-timeline-element-title text-left ${defaultTextStyle} font-bold !text-2xl ${isAccent && "xl:text-right"}`}>{title}</h1>
    )
  }

  const ExperienceSubHeader = ({ title }: { title: string }) => {
    return (
      <h2 className={`vertical-timeline-element-title text-left ${isAccent ? "dark:!text-slate-600 !text-slate-200" : "dark:!text-slate-200 !text-slate-600"} font-bold !text-lg ${isAccent && "xl:text-right"}`}>{title}</h2>
    )
  }

  const ExperienceResponsibilitiesList = ({ responsibilities }: { responsibilities: string[] }) => {
    return (
      <ul className={`${isAccent ? "text-white dark:text-black" : "text-slate-600 dark:text-slate-200"}`}>
        {
          responsibilities.map((responsibility: string) => {
            return (
              <li key={responsibility} className="flex items-start leading-relaxed"> <span className="mr-2"> &#8226; </span> {responsibility} </li>
            )
          })
        }
      </ul>
    )
  }

  const contentStyle = isAccent ? accentContentStyle : defaultContentStyle;
  const arrowStyle = isAccent ? accentArrowStyle : defaultArrowStyle;
  const validExperience = accomplishments.length && responsibilities.length;

  const handleOnClick = () => {
    if (validExperience) {
      setOpen(!open)
    }
  }

  return (
    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      contentStyle={contentStyle}
      contentArrowStyle={arrowStyle}
      date={date}
      dateClassName="!text-lg !font-semibold text-left text-gray-900 dark:text-slate-200 duration-150 !hidden xl:!block"
      iconStyle={iconStyle}
      icon={<CompanyLogo companyName={companyName} />}
      visible
    >
      <div className={`${open ? "max-h-[2000px]" : "max-h-[210px] xl:max-h-[180px]"} duration-150 p-4 xl:p-4 ${validExperience && "cursor-pointer"} overflow-hidden`}
        onClick={handleOnClick}>
        <ExperienceHeader title={companyName} />
        <div className={`block xl:hidden font-semibold ${defaultTextStyle}`}>
          {date}
        </div>
        <ExperienceSubHeader title={jobTitle} />
        <ExperienceSubHeader title={jobLocation} />
        {
          validExperience ? (
          <div className={chevronIconStyle}>
            {open ? <ArrowUpCircleIcon /> : <ArrowDownCircleIcon /> }
          </div>
          ) : (
            <div className={defaultIconStyle}>
              <WrenchScrewdriverIcon />
            </div>
          )
        }
        <Divider size="md" inverseColors={!isAccent} />
        <div className='mt-4'>
          {responsibilities.length ? <ExperienceResponsibilitiesList responsibilities={responsibilities} /> : null}
          {accomplishments.length ? <h4 className={`font-bold ${isAccent ? "text-white dark:text-black" : "text-slate-600 dark:text-slate-200"} text-lg my-2`}> Key Features Developed / Accomplishments: </h4> : null}
          {accomplishments.length ? <ExperienceResponsibilitiesList responsibilities={accomplishments} /> : null}
        </div>
      </div>
    </VerticalTimelineElement>
  )
}

export default ExperienceJobCard;
